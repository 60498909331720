export default function contentMenu() {
  const contentMenuButton = document.querySelector(
    "[data-content-menu-button]"
  );

  if (!contentMenuButton) return;

  const contentMenuList = document.querySelector("[data-content-menu-list]");

  const toggleMenu = () => {
    contentMenuList.classList.toggle("max-h-0");
    contentMenuList.classList.toggle("max-h-96");
    contentMenuButton.classList.toggle("after:rotate-180");
    contentMenuButton.classList.toggle("after:mb-2");
    contentMenuButton.classList.toggle("after:mb-0");
  };

  contentMenuButton.addEventListener("click", () => {
    toggleMenu();
  });
}
