export default function form() {
  const forms = Array.from(document.querySelectorAll("[data-archive-filter]"));
  if (!forms) return;

  const disableEmptyFilters = (selects) => {
    selects.forEach((select) => {
      if (select.value === "") {
        select.disabled = true;
      }
    });
  };

  forms.forEach((formElement) => {
    const selects = formElement.querySelectorAll("select");
    if (!selects) return;

    selects.forEach((select) => {
      select.addEventListener("change", () => {
        disableEmptyFilters(selects);
        formElement.submit();
      });
    });
  });
}
