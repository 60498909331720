export default function ataCarnet() {
  document.querySelectorAll("[data-ata-carnet]").forEach(async (block) => {
    let totalValue;
    let numTrips;

    const form = block.querySelector("[data-ata-form]");
    const container = block.querySelector("[data-ata-container]");
    const templateHeader = block.querySelector("[data-ata-template-header]");
    const templateBody = block.querySelector("[data-ata-template-body]");
    const templateSecurityDeposit = block.querySelector(
      "[data-ata-template-deposit]"
    );
    const thead = block.querySelector("[data-ata-head]");
    const tbody = block.querySelector("[data-ata-body]");
    const securityDeposit = block.querySelector("[data-ata-security-deposit]");

    const populateContainer = (data) => {
      thead.innerHTML = "";
      tbody.innerHTML = "";
      securityDeposit.innerHTML = "";
      const header = templateHeader.content.cloneNode(true);
      const body = templateBody.content.cloneNode(true);
      const deposit = templateSecurityDeposit.content.cloneNode(true);

      const tr = body.querySelectorAll("tr");
      deposit.querySelector(
        '[data-carnet-value="security_deposit"]'
      ).textContent = new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "sek",
      }).format(data.security_deposit.value);

      tr.forEach((row) => {
        const valueRow = row.querySelector("[data-carnet-value]");
        const value = valueRow.dataset.carnetValue;
        const valueMemberRow = row.querySelector("[data-carnet-value-member]");
        const valueMember = valueMemberRow.dataset.carnetValueMember;

        if (value && valueRow) {
          valueRow.textContent = new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "sek",
          }).format(data[`${value}`].value);
        }

        if (valueMember && valueMemberRow) {
          valueMemberRow.textContent = new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "sek",
          }).format(data[`${valueMember}`].value_member);
        }
      });

      thead.appendChild(header);
      tbody.appendChild(body);
      securityDeposit.appendChild(deposit);
    };

    async function load(formData) {
      try {
        const response = await fetch(
          "/wp-json/shk/v1/calculate-ata-carnet-cost",
          {
            method: "POST",
            body: formData,
          }
        );
        const result = await response.json();
        populateContainer(result);
      } catch (error) {
        console.error("API-Error:", error);
      }
    }
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      const formData = new FormData();

      totalValue = block.querySelector("[data-input-value]").value;
      numTrips = block.querySelector("[data-num-trips]").value;

      const service = block.querySelectorAll('[name="service"]');

      const serviceValue = Array.from(service).find(
        (radio) => radio.checked
      ).value;
      if (!totalValue) {
        console.error("input error");
      } else {
        formData.append("value", totalValue);
        formData.append("num_trips", numTrips);
        formData.append("express_service", serviceValue === "express");
        load(formData);
      }
    });

    container.appendChild(templateBody);
  });
}
