import { preventScroll, restoreScroll } from "../utils/prevent-scroll";

export default function modal() {
  const modalButtons = document.querySelectorAll("[data-modal-open-button]");
  if (!modalButtons.length) return;

  let modalOpen = false;
  let scrollPosition = 0;

  modalButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const modalEl = document.querySelector(
        '[data-modal][data-modal-type="' + button.dataset.modalType + '"]'
      );

      const closeModalButton = modalEl.querySelector(
        "[data-modal-close-button]"
      );
      const iframeEl = modalEl.querySelector("iframe");

      scrollPosition = window.scrollY;
      preventScroll(scrollPosition);
      modalOpen = true;
      modalEl.classList.remove("hidden");
      if (iframeEl) {
        iframeEl.src = iframeEl.dataset.src;
      }

      const closeModal = () => {
        modalOpen = false;
        if (iframeEl) {
          iframeEl.src = "";
        }
        modalEl.classList.add("hidden");
        restoreScroll(scrollPosition);
      };

      modalEl.addEventListener("click", () => {
        if (modalOpen) closeModal();
      });

      modalEl
        .querySelector("[data-modal-inner]")
        .addEventListener("click", (e) => {
          e.stopPropagation();
        });

      closeModalButton.addEventListener("click", () => {
        closeModal();
      });
    });
  });
}
