const preventScrollClass = "u-preventScroll";

export const preventScroll = (scrollPosition) => {
  // Prevent scroll on background body
  document.body.classList.add(preventScrollClass);
  document.body.style.top = `calc(-${scrollPosition}px + var(--wp-admin--admin-bar--height, 0px))`;
};

export const restoreScroll = (scrollPosition) => {
  // Restore scroll position on body
  document.body.classList.remove(preventScrollClass);
  document.body.style.top = "";
  document.documentElement.scrollTop = scrollPosition;
};
