import lottie from "lottie-web";
import animationData from "../../../animations/icon-hamburger-animation.json";
import { preventScroll, restoreScroll } from "../utils/prevent-scroll";

export default function headerMenu() {
  const menuButton = document.querySelector("[data-menu-button]");
  const icon = menuButton.querySelector("[data-menu-icon");
  const menu = document.querySelector("[data-menu]");
  const listWithChildren = document.querySelectorAll(".menu-item-has-children");

  let menuOpen = false;

  const hamburgerAnimation = lottie.loadAnimation({
    container: icon,
    renderer: "svg",
    loop: false,
    autoplay: false,
    animationData,
  });

  const handleMenuToggle = () => {
    menu.classList.toggle("hidden");
    menuOpen = !menuOpen;
    if (menuOpen) {
      preventScroll(0);
      hamburgerAnimation.playSegments([0, 24], true);
    } else {
      restoreScroll(0);
      hamburgerAnimation.playSegments([24, 38], true);
    }
  };

  const handleOutsideClick = (event) => {
    if (menuOpen) {
      if (!menuButton.contains(event.target) && !menu.contains(event.target)) {
        handleMenuToggle();
      }
    }
  };

  const menuButtonClick = () => {
    handleMenuToggle();
  };

  const showMoreButtonClick = (e) => {
    e.target.classList.toggle("active");
    const { listId } = e.target.dataset;
    const parentList = document.getElementById(listId);
    const childList = parentList.getElementsByTagName("ul");

    childList[0].style.display =
      childList[0].style.display === "block" ? "none" : "block";
  };

  listWithChildren.forEach((listEl) => {
    const showMoreButton = document.createElement("button");
    showMoreButton.classList.add("showMoreButton");
    showMoreButton.dataset.listId = listEl.id;

    listEl.appendChild(showMoreButton);

    showMoreButton.addEventListener("click", (e) => showMoreButtonClick(e));
  });

  menuButton.addEventListener("click", () => menuButtonClick());

  window.addEventListener("click", handleOutsideClick);
}
