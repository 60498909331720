export default function newsletter() {
  document.querySelectorAll("[data-news-letter]").forEach((form) => {
    if (form) {
      const context = form.dataset.newsLetter;
      form.addEventListener(
        "focusin",
        () => {
          // in case of other placements the newsletter might expand in a different way?
          if (context === "footer") {
            form
              .querySelector("[data-news-letter-fields]")
              .classList.remove("sr-only");

            form.scrollIntoView();
          }
        },
        { once: true }
      );
    }
  });
}
