const root = document.documentElement;

const measure = () => {
  const rect = root.getBoundingClientRect();
  setTimeout(() => {
    root.style.setProperty("--vw", `${rect.width}px`);
  }, 0);
};

if (window.ResizeObserver) {
  const resizeObserver = new ResizeObserver(measure);
  resizeObserver.observe(root);
} else {
  window.addEventListener("resize", measure);
}

measure();
