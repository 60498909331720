import ataCarnet from "./components/ata-carnet";
import carousel from "./components/carousel";
import contentMenu from "./components/content-menu";
import form from "./components/form";
import headerMenu from "./components/header-menu";
import modal from "./components/modal";
import search from "./components/search";
import newsletter from "./components/newsletter";

import "./utils/vw";

window.addEventListener("DOMContentLoaded", () => {
  ataCarnet();
  carousel();
  contentMenu();
  headerMenu();
  form();
  modal();
  search();
  newsletter();
});
