export default function carousel() {
  const carousels = Array.from(document.querySelectorAll("[data-carousel]"));
  if (!carousels) return;

  carousels.forEach((carouselWrapper) => {
    let currentItem = 0;
    const list = carouselWrapper.querySelector("[data-carousel-list]");
    if (!list) return;

    const prevButton = carouselWrapper.querySelector("[data-carousel-prev]");
    const nextButton = carouselWrapper.querySelector("[data-carousel-next]");
    const opacityCn = "opacity-25";

    const autoPlay = carouselWrapper.dataset.carousel === "autoplay";
    if ("IntersectionObserver" in window) {
      const prevObserver = new IntersectionObserver(
        (entries) => {
          const first = entries[0];
          if (first && first.isIntersecting) {
            prevButton.classList.add(opacityCn);
            prevButton.disabled = true;
          } else {
            prevButton.classList.remove(opacityCn);
            prevButton.disabled = false;
          }
        },
        {
          root: list,
          threshold: [0.3],
        }
      );

      const nextObserver = new IntersectionObserver(
        (entries) => {
          const last = entries[0];
          if (last && last.isIntersecting) {
            nextButton.classList.add(opacityCn);
            nextButton.disabled = true;
          } else {
            nextButton.classList.remove(opacityCn);
            nextButton.disabled = false;
          }
        },
        {
          root: list,
          threshold: [0.3],
        }
      );

      prevObserver.observe(
        list.querySelector("[data-carousel-item]:first-child")
      );
      nextObserver.observe(
        list.querySelector("[data-carousel-item]:last-child")
      );
    }

    const navigate = (value, wrap = false) => {
      const prevItem = list.querySelectorAll("[data-carousel-item]")[
        currentItem
      ];
      const prevVideo =
        prevItem && prevItem.querySelector(".Embed--video iframe");

      // stop embedded video from playing (this could be improved by using the youtube api to stop the video, but this is to reduce unnecessary javascript overhead)
      if (prevVideo) {
        const { src } = prevVideo;
        prevVideo.src = "";
        prevVideo.setAttribute("src", src);
      }

      currentItem += value;
      const item = list.querySelector("[data-carousel-item]");

      if (wrap && nextButton.disabled) {
        list.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        list.scrollBy({
          top: 0,
          left: value * item.offsetWidth,
          behavior: "smooth",
        });
      }
    };
    if (autoPlay) {
      setInterval(() => {
        navigate(1, true);
      }, 5000);
    }
    nextButton.addEventListener("click", () => navigate(1));
    prevButton.addEventListener("click", () => navigate(-1));
  });
}
