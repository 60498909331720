export default function headerMenu() {
  const menuSearchButton = document.querySelector("[data-search-menu-button]");
  const searchComponent = document.querySelector("[data-search]");
  const closeButton = document.querySelector("[data-search-close-button]");

  let modalOpen = false;

  const handleSearchMenuToggle = () => {
    searchComponent.classList.toggle("hidden");
    document.body.classList.toggle("overflow-hidden");
    modalOpen = !modalOpen;
  };

  const handleOutsideClick = (event) => {
    if (modalOpen) {
      if (
        !menuSearchButton.contains(event.target) &&
        !searchComponent.contains(event.target)
      ) {
        handleSearchMenuToggle();
      }
    }
  };

  const menuSearchButtonClick = () => {
    window.scrollTo(0, 0);
    handleSearchMenuToggle();
  };

  menuSearchButton.addEventListener("click", () => menuSearchButtonClick());

  closeButton.addEventListener("click", () => {
    handleSearchMenuToggle();
  });

  window.addEventListener("click", handleOutsideClick);
}
